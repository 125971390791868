import {
  AlarmOnOutlined,
  AssessmentOutlined,
  BarChart,
  CardTravel,
  CheckCircleOutline,
  CheckCircleOutlineOutlined,
  CheckCircleTwoTone,
  CreditCard,
  CreditCardOffOutlined,
  CreditScore,
  Description,
  DoNotDisturbOn,
  HelpOutline,
  HourglassBottomTwoTone,
  Logout,
  PauseCircleTwoTone,
  Payment,
  Payments,
  Timeline,
  Visibility,
} from "@mui/icons-material";

import {
  getCurrentDate,
  getFinancialPeriod,
  getFinancialPeriodV4,
  getFinancialYear,
} from "../../../utils/Date";
import { currentDate } from "../../../hooks/common/useDashboard";
import { useEffect, useState } from "react";
import { currencyFormatter, numberFormatter } from "../../../utils/formatters";
import useForm from "../../../hooks/common/useForm";
import useLocationData from "../../../hooks/common/useLocationData";
import { TagSpan } from "../../elements/Styled";
import {
  AssessIcon,
  CollectionsIcon,
  RegisterIcon,
} from "../../../assets/svg/SvgIcons";
import { useAuth } from "../../../context/authContext";
import { percentageChange, percentageChangeFys } from "../../../utils/mathUtil";
import { UserProfileView } from "../users/UserProfileView";
import {
  ADMIN,
  APP_AUTHORITY,
  CAN_ADMIN,
  DATA_ENTRANT,
  FRONT_DESK_OFFICER,
  INSPECTED_STATUS,
  MUN_APPROVER,
  PROPERTY_VALUATION,
  PUBLIC_APP_USER,
  STATUS_BUDGET_PERFORMANCE,
  STATUS_DEVELOPMENT_CONTROL,
  STATUS_REVENUE_FORECAST,
  STATUS_SUMMARY,
  STATUS_SUMMARY_FYS,
  VALUATION_APPROVER,
  VALUATION_DATA_ENTRANT,
} from "../../../constants/webapp";
import Loader from "../../common/Loader";
import useGenericGet from "../../../hooks/common/useGenericGet";
import {
  BUDGET_PERFORMANCE_API,
  DASHBOARD_SUMMARY_API,
  DEVELOPMENT_CONTROL_API,
  GRIEVANCES_ASSIGNMENTS_SUMMARY_API,
  GRIEVANCES_SUMMARY_API,
  REPORT_SUMMARY_FYS_API,
  REVENUE_FORECAST_API,
} from "../../../constants/api";
import { ReportFilter } from "./ReportFilter";
import {
  DashboardStatGraph,
  DashboardStatTable,
  TopSection,
} from "./DashboardContent";
import AssignedEnquiries from "../enquiries/AssignedEnquires";
import {
  GetPermissions,
  level1Permissions,
} from "../../../utils/permissionsUtils";
import { returnObject } from "../../../utils/helperUtil";
import { developmentControlRoles } from "../../../utils/authUtils";
const date = "Today - " + getCurrentDate();

// tile data object
const tileModel = (title, value, desc, Icon) => {
  return { title: title, value: value, desc: desc, Icon: Icon };
};

// tile props
const tileProps = (sectionTitle, tileData, infoTag) => {
  return {
    sectionTitle: sectionTitle,
    tileData: tileData,
    infoTag: infoTag,
  };
};

const revenuePerformanceData = [
  tileModel("Registrations", 0, "Daily registrations submitted", CardTravel),
  tileModel(
    "Assessments",
    0 + "/=",
    "Daily assessments submitted",
    AssessmentOutlined
  ),
  tileModel(
    "Collections",
    0 + "/=",
    "Daily collections submitted",
    CreditScore
  ),
];

// Main Dashboard

const mainDashboardTiles = [
  tileProps("Service Desk Summary", [], "Service"),
  tileProps(
    "Revenue Performance - District Name",
    revenuePerformanceData,
    date
  ),
];

const mainDashboard = {
  tileData: mainDashboardTiles,
  statData: {
    title: [
      ["Revenue Performance Report", "Today"],
      ["Revenue Performance By Chart Of Accounts", getCurrentDate()],
    ],
    component: [],
  },
};

// Supervisor Development Control
var descDC = "0% higher than last F/Y";
const tagDC = `FY - ${getFinancialYear()}`;

const developmentControlPerformance1 = [
  tileModel("Submitted", 0, descDC, Description),
  tileModel("Total Amount Collected", 0, descDC, Payments),
  tileModel("Reviewed", 0, descDC, Visibility),
  tileModel("Pending Review", 0, descDC, HourglassBottomTwoTone),
];

const frontDesk = [
  tileModel("Submitted", 0, descDC, HelpOutline),
  tileModel("Forwarded", 0, descDC, CheckCircleTwoTone),
  tileModel("Deferred", 0, descDC, AlarmOnOutlined),
  tileModel("Assessed", 0, descDC, Payment),
];

const dataEntrantSection1 = [
  tileModel("Submitted", 0, descDC, Description),
  tileModel("Forwarded", 0, descDC, CheckCircleTwoTone),
  tileModel("Assessed", 0, descDC, Payment),
];

const dataEntrantSection2 = [
  tileModel("Deferred", 0, descDC, AlarmOnOutlined),
  tileModel("Total Amount Collected", 0, descDC, Payments),
];
const physicalPlanning = [
  tileModel("Approved", 0, descDC, CheckCircleOutlineOutlined),
  tileModel("Deferred", 0, descDC, PauseCircleTwoTone),
  tileModel("Rejected", 0, descDC, DoNotDisturbOn),
];
const enforcement = [
  tileModel("Compliant", 0, null, CreditCard),
  tileModel("Non-Compliant", 0, null, CreditCardOffOutlined),
];

const developmentControlTiles = [
  tileProps(
    "Development Control Performance",
    developmentControlPerformance1,
    tagDC
  ),
  tileProps("Physical Planning Committee Decision", physicalPlanning, tagDC),

  tileProps("Enforcement (Compliant & Non-Compliant)", enforcement, tagDC),
];

const supervisorDevelopmentControl = {
  tileData: developmentControlTiles,
  statData: {
    title: [
      ["Performance Report", "Today"],
      ["Permit Performance By Collection"],
    ],
    component: [],
  },
};

// DATA ENTRANT

const dataEntrantTiles = [
  tileProps("Development Control Performance", dataEntrantSection1, tagDC),

  tileProps(null, dataEntrantSection2),
  tileProps("Physical Planning Committee Decision", physicalPlanning, tagDC),

  tileProps("Enforcement (Compliant & Non-Compliant)", enforcement, tagDC),
];

const dataEntrantDashboard = {
  tileData: dataEntrantTiles,
  statData: {
    title: [
      ["Performance Report", "Today"],
      ["Permit Performance By Collection"],
    ],
    component: [],
  },
};

// Front Desk Dashboard

const frontDeskTiles = [
  tileProps("Development Control Performance", frontDesk, tagDC),
  tileProps("Enforcement (Compliant & Non-Compliant)", enforcement, tagDC),
];

const frontDeskDashboard = {
  tileData: frontDeskTiles,
  statData: {
    title: [
      ["Performance Report", "Today"],
      ["Permit Performance By Collection"],
    ],
    component: [],
  },
};

// ADMINISTRATORS

export function ReportsSummary(status) {
  const [tag, setTag] = useState("ALL");
  const [statusTag, setStatusTag] = useState(false);
  const [loadRefetch, setLoadRefetch] = useState(true);
  const { form, updateFormValue, updateFormValues } = useForm({});
  const [filters, setFilter] = useState({
    start_date: status !== STATUS_SUMMARY ? currentDate : "",
    fin_year: status === STATUS_SUMMARY ? getFinancialYear() : "",
  });
  const {
    districts,
    divisions,
    division,
    parishes,
    parish,
    municipality,
    mun_name,
  } = FilterOptions(form);
  const { loadData: getSummaryData, error, data: reports } = useGenericGet();
  const permissions = GetPermissions(level1Permissions);
  const adminPermissions = GetPermissions([CAN_ADMIN]);

  function fetchData() {
    getSummaryData({
      api: DASHBOARD_SUMMARY_API,
      params: {
        ...filters,
        time: new Date().toLocaleString(),
      },
      replace: false,
    });
  }

  useEffect(() => {
    fetchData();
    let interval = setInterval(() => fetchData(), 30000);
    return () => clearInterval(interval);
  }, [filters]);

  useEffect(() => {
    if (reports) {
      setLoadRefetch(false);
    }
  }, [reports]);

  useEffect(() => {
    if (filters?.start_date === currentDate) {
      setStatusTag("Today - " + getCurrentDate());
    } else if (filters?.fin_year) {
      setStatusTag("FY - " + filters?.fin_year);
    } else {
      setStatusTag("Cumulative");
    }

    if (filters?.municipality !== municipality) {
      setTag(null);
    } else {
      if (!adminPermissions) {
        setTag(mun_name);
      }
    }
  }, [filters]);

  useEffect(() => {
    let values;
    if (permissions) {
      values = {
        ...returnObject(!adminPermissions, { municipality: municipality }),
        fin_year: status === STATUS_SUMMARY ? getFinancialYear() : "",
      };
    } else {
      values = {
        municipality: municipality,
        division: division,
        parish: parish,
      };
    }
    updateFormValues(values);
    if (!adminPermissions) {
      setTag(mun_name);
    }
  }, [municipality, permissions, adminPermissions]);

  const reportSummary = [
    tileModel(
      "Registrations",
      numberFormatter(reports?.sum_registrations),

      status === STATUS_SUMMARY
        ? percentageChange(
            reports?.year_reg_count,
            reports?.last_year_reg_count
          ).full_text
        : "Daily Registrations submitted",
      RegisterIcon
    ),
    tileModel(
      "Assessments",
      currencyFormatter(reports?.sum_assessments),

      status === STATUS_SUMMARY
        ? percentageChange(
            reports?.year_assess_amount,
            reports?.last_year_assess_amount
          ).full_text
        : "Daily Assessments submitted",
      AssessIcon
    ),
    tileModel(
      "Collections",
      currencyFormatter(reports?.sum_collections),
      status === STATUS_SUMMARY
        ? percentageChange(
            reports?.year_coll_amount,
            reports?.last_year_coll_amount
          ).full_text
        : "Daily Collections submitted",
      CollectionsIcon
    ),
  ];

  const reportsSummaryTiles = [
    tileProps(
      <p>Revenue Performance {tag && <TagSpan>{tag}</TagSpan>}</p>,
      reportSummary,
      status === STATUS_SUMMARY ? "FY - " + getFinancialYear() : statusTag
    ),
  ];

  const reportsSummaryDashboard = {
    tileData: reportsSummaryTiles,
    statData: {
      title: [
        ["Revenue Performance Report", statusTag],
        ["Revenue Performance By Chart of Accounts", statusTag],
      ],
      table: {
        filter: {
          setFilter,
          form,
          updateFormValue,
          updateFormValues,
          districts,
          divisions,
          parishes,
          error,
          financial_years: reports?.financial_years,
          loading: loadRefetch,
        },
        rows: reports?.cumulative_report,
        totals: {
          assessments: reports?.sum_assessments,
          registrations: reports?.sum_registrations,
          collections: reports?.sum_collections,
        },
      },
      graph: reports?.cumulative_report,
    },
  };

  return reportsSummaryDashboard;
}

export function ReportsSummaryFys() {
  const [tag, setTag] = useState("ALL");
  // const [statusTag, setStatusTag] = useState(false);
  const { form, updateFormValue, updateFormValues } = useForm({});
  const [filters, setFilter] = useState();
  const {
    districts,
    divisions,
    parishes,
    // municipality, mun_name
  } = FilterOptions(form);
  const {
    loadData: getSummaryFysData,
    loading,
    error,
    data: reports,
  } = useGenericGet();

  useEffect(() => {
    getSummaryFysData({
      api: REPORT_SUMMARY_FYS_API,
      params: {
        ...filters,
      },
    });
  }, [filters]);

  // useEffect(() => {
  //   if (filters?.start_date === currentDate) {
  //     setStatusTag("Today - " + getCurrentDate());
  //   } else if (filters?.fin_year) {
  //     setStatusTag("FY - " + filters?.fin_year);
  //   } else {
  //     setStatusTag("Cumulative");
  //   }

  //   if (filters?.municipality !== municipality) {
  //     setTag(null);
  //   } else {
  //     setTag(mun_name);
  //   }
  // }, [filters]);

  // useEffect(() => {
  //   updateFormValues({
  //     municipality: municipality,
  //   });
  //   setTag(mun_name);
  // }, [municipality]);

  // THIS FY
  const this_year_summary = [
    tileModel(
      "Registrations",
      numberFormatter(reports?.this_sum_registrations),

      percentageChangeFys(
        reports?.this_sum_registrations,
        reports?.last_fy_sum_registrations
      ).archieved,
      RegisterIcon
    ),
    tileModel(
      "Assessments",
      currencyFormatter(reports?.this_sum_assessments),

      percentageChangeFys(
        reports?.this_sum_assessments,
        reports?.last_fy_sum_assessments
      ).archieved,
      AssessIcon
    ),
    tileModel(
      "Collections",
      currencyFormatter(reports?.this_sum_collections),

      percentageChangeFys(
        reports?.this_sum_collections,
        reports?.last_fy_sum_collections
      ).archieved,
      CollectionsIcon
    ),
  ];

  // LAST FY
  const last_year_summary = [
    tileModel(
      "Registrations",
      numberFormatter(reports?.last_fy_sum_registrations),

      percentageChangeFys(
        reports?.this_sum_registrations,
        reports?.last_fy_sum_registrations
      ).comparison,
      RegisterIcon
    ),
    tileModel(
      "Assessments",
      currencyFormatter(reports?.last_fy_sum_assessments),

      percentageChangeFys(
        reports?.this_sum_assessments,
        reports?.last_fy_sum_assessments
      ).comparison,
      AssessIcon
    ),
    tileModel(
      "Collections",
      currencyFormatter(reports?.last_fy_sum_collections),

      percentageChangeFys(
        reports?.this_sum_collections,
        reports?.last_fy_sum_collections
      ).comparison,
      CollectionsIcon
    ),
  ];

  const reportsSummaryTiles = [
    tileProps(
      <p>Revenue Performance - This FY {tag && <TagSpan>{tag}</TagSpan>}</p>,
      this_year_summary,
      getFinancialYear()
    ),

    tileProps(
      "Revenue Performance",
      last_year_summary,
      getFinancialPeriodV4(-1)
    ),
  ];

  const filter = {
    setFilter,
    form,
    updateFormValue,
    updateFormValues,
    districts,
    divisions,
    parishes,
    error,
    financial_years: reports?.financial_years,
    loading,
  };
  const this_year_table = {
    rows: reports?.this_cumulative_report,
    totals: {
      assessments: reports?.this_sum_assessments,
      registrations: reports?.this_sum_registrations,
      collections: reports?.this_sum_collections,
    },
  };

  const last_year_table = {
    rows: reports?.last_cumulative_report,
    totals: {
      assessments: reports?.last_sum_assessments,
      registrations: reports?.last_sum_registrations,
      collections: reports?.last_sum_collections,
    },
  };
  const this_year_graph = reports?.this_cumulative_report;
  const last_year_graph = reports?.last_cumulative_report;

  const reportsSummaryDashboard = {
    tileData: reportsSummaryTiles,
    components: <ReportFilter filter={filter} />,
    statData: {
      components: [
        <DashboardStatTable
          table={this_year_table}
          title="Revenue Performance Report"
          tag={getFinancialPeriod()}
        />,
        <DashboardStatTable
          table={last_year_table}
          title="Revenue Performance Report"
          tag={getFinancialPeriodV4(-1)}
        />,
        <DashboardStatGraph
          graph={this_year_graph}
          title="Revenue Performance Report"
          tag={getFinancialPeriod()}
        />,
        <DashboardStatGraph
          graph={last_year_graph}
          title="Revenue Performance Report"
          tag={getFinancialPeriodV4(-1)}
        />,
      ],
    },
  };

  return reportsSummaryDashboard;
}

export function FilterOptions(form, status) {
  // Select Filter Data
  const {
    districts,
    getDistricts,
    divisions,
    getDivisions,
    parishes,
    getParishes,
    villages,
    getVillages,
    streets,
    getStreets,
  } = useLocationData();
  const { user } = useAuth();

  let mun_name = user?.profile?.municipality_details?.name;
  let municipality = user?.profile?.municipality;
  let division = user?.profile?.division;
  let parish = user?.profile?.parish;

  // Get District Data
  useEffect(() => {
    getDistricts();
  }, [form?.municipality]);

  // Get Division Data
  useEffect(() => {
    if (form?.municipality) getDivisions(form?.municipality);
  }, [form?.municipality]);

  // Get Parishes Data
  useEffect(() => {
    if (form?.division) getParishes(form?.division);
  }, [form?.division]);

  // Get Villages Data
  useEffect(() => {
    if (status === PROPERTY_VALUATION) {
      if (form?.parish) getVillages(form?.parish);
    }
  }, [form?.parish]);

  // Get Street Data
  useEffect(() => {
    if (status === PROPERTY_VALUATION) {
      if (form?.village) getStreets(form?.village);
    }
  }, [form?.village]);

  return {
    districts,
    divisions,
    division,
    parishes,
    parish,
    villages,
    streets,
    mun_name,
    municipality,
  };
}

export function RevenueForecast() {
  const { user } = useAuth();
  const [tag, setTag] = useState("ALL");
  const { form, updateFormValue, updateFormValues } = useForm({});
  const [filters, setFilter] = useState({});
  const { districts, divisions, parishes } = FilterOptions(form);

  const {
    loadData: getDevControlSummaryData,
    loading,
    error,
    data: reports,
  } = useGenericGet();

  useEffect(() => {
    getDevControlSummaryData({
      api: REVENUE_FORECAST_API,
      params: { ...filters },
    });
  }, [filters]);

  useEffect(() => {
    let mun_name = user?.profile?.municipality_details?.name;
    let municipality = user?.profile?.municipality;

    updateFormValues({
      municipality: municipality,
      fin_year: getFinancialYear(),
    });
    setTag(mun_name);
  }, [user]);

  const percentage = percentageChange(
    reports?.sum_collections,
    reports?.last_year_coll_amount
  );

  const revenueForecast = [
    tileModel(
      "Last Year Collections",
      currencyFormatter(reports?.last_year_coll_amount),
      "Collections for the last F/Y year",
      BarChart
    ),
    tileModel(
      "Forecast Collections",
      currencyFormatter(reports?.sum_collections),
      "Forecast for the current F/Y year",
      CollectionsIcon
    ),
    tileModel(
      "Percentage",
      percentage.percentage,
      `${percentage.text} ${percentage.suffix}`,
      Timeline
    ),
  ];

  const revenueForecastTiles = [
    tileProps(
      <p>Revenue Forecast {tag && <TagSpan>{tag}</TagSpan>}</p>,
      revenueForecast,
      "FY - " + getFinancialYear()
    ),
  ];

  const revenueForecastDashboard = {
    tileData: revenueForecastTiles,
    statData: {
      title: [
        ["Revenue Forecast", "Per Revenue Source"],
        ["Revenue Forecast", "Bar Graph Scale"],
      ],
      table: {
        filter: {
          setFilter,
          form,
          updateFormValue,
          districts,
          divisions,
          parishes,
          error,
          financial_years: reports?.financial_years,
          loading,
        },
        rows: reports?.cumulative_report,
        columns: ["collections"],
        totals: {
          collections: reports?.sum_collections,
        },
      },
      graph: reports?.cumulative_report,
    },
  };

  return revenueForecastDashboard;
}

// BUDGET PERFORMANCE REPORT
export function BudgetPerformanceReport() {
  const {
    data: reports,
    loadData: getBudgetPerformance,
    loading,
    error,
  } = useGenericGet();

  const [filters, setFilter] = useState({});
  const { form, updateFormValue, updateFormValues } = useForm({});
  const { districts, divisions, parishes, mun_name, municipality } =
    FilterOptions(form);

  useEffect(() => {
    getBudgetPerformance({
      api: BUDGET_PERFORMANCE_API,
      params: { ...filters },
    });
  }, [filters]);

  useEffect(() => {
    updateFormValues({
      municipality: municipality,
    });
  }, [municipality]);

  const percentage = percentageChange(
    reports?.sum_collections,
    reports?.last_year_coll_amount
  );

  const budgetPerformance = [
    tileModel(
      "AMOUNT BUDGETED",
      currencyFormatter(reports?.sum_budgeted),
      "Budgeted for the current F/Y year",
      AssessIcon
    ),
    tileModel(
      "Amount Collected",
      currencyFormatter(reports?.sum_collections),
      "Collected for the current F/Y year",
      CollectionsIcon
    ),
    tileModel(
      "Percentage",
      percentage.percentage,
      `${percentage.text} ${percentage.suffix}`,
      Timeline
    ),
  ];

  const budgetTiles = [
    tileProps(
      <p>
        Revenue Budget Performance {mun_name && <TagSpan>{mun_name}</TagSpan>}
      </p>,
      budgetPerformance,
      "FY - " + getFinancialYear()
    ),
  ];

  const budgetReport = {
    tileData: budgetTiles,
    statData: {
      title: [
        ["Revenue Budget Performance", "Per Revenue Source"],
        ["Revenue Budget Performance", "Bar Graph Scale"],
      ],
      table: {
        filter: {
          setFilter,
          form,
          updateFormValue,
          districts,
          divisions,
          parishes,
          error,
          financial_years: reports?.financial_years,
          loading,
        },
        rows: reports?.cumulative_report,
        // columns: ["collections"],
        ammend_column_keys: ["Account", "Budgeted", "Collections"],
        ammend_row_keys: ["account", "budgeted", "collections"],
        totals: {
          collections: reports?.sum_collections,
        },
      },
      graph: reports?.cumulative_report,
    },
  };

  return budgetReport;
}

// ENQUIRY DASHBOARD
export function EnquiriesSummary() {
  const { data: assignments, loadData: getAssignments } = useGenericGet();

  const { data: summary, loadData: getSummary } = useGenericGet();

  const { user } = useAuth();
  let mun_name = user?.profile?.municipality_details?.name;

  useEffect(() => {
    getAssignments({ api: GRIEVANCES_ASSIGNMENTS_SUMMARY_API });
    getSummary({ api: GRIEVANCES_SUMMARY_API });
  }, []);

  // tile data array

  const summaryData = [
    tileModel(
      "Pending Inquiries",
      numberFormatter(summary?.query_stats.pending),
      "Overall Pending Inquiries",
      HelpOutline
    ),
    tileModel(
      "Resolved Inquiries",
      numberFormatter(summary?.query_stats.resolved),
      "Overall Resolved Inquiries",
      CheckCircleOutline
    ),
    tileModel(
      "Reopened Inquiries",
      numberFormatter(summary?.query_stats.reopened),
      "Overall Reopened Inquiries",
      Logout
    ),
  ];

  const assignmentData = [
    tileModel(
      "Pending Inquiries",
      numberFormatter(assignments?.query_stats.pending),
      "Unresolved inquiries assigned to me",
      HelpOutline
    ),
    tileModel(
      "Resolved Inquiries",
      numberFormatter(assignments?.query_stats.resolved),
      "Resolved Inquiries assigned to me",
      CheckCircleOutline
    ),
    tileModel(
      "Reopened Inquiries",
      numberFormatter(assignments?.query_stats.reopened),
      "Reopened Inquiries assigned to me",
      Logout
    ),
    tileModel(
      "Overdue Inquiries",
      numberFormatter(assignments?.query_stats.overdue),
      "Inquiries delayed past the suggested resolution date",
      AlarmOnOutlined
    ),
  ];

  const enquiryTiles = [
    tileProps(
      <p>Service Desk Summary {mun_name && <TagSpan>{mun_name}</TagSpan>}</p>,
      summaryData,
      date
    ),
    tileProps("Your Assigned Enquires", assignmentData, date),
  ];

  return enquiryTiles;
}

// DEVELOPMENT CONTROL
export function DevelopmentControlSummary(status) {
  const { form, updateFormValue, updateFormValues } = useForm({});
  const {
    loadData: getDevControlSummaryData,
    loading,
    error,
    data: reports,
  } = useGenericGet();
  const [filters, setFilter] = useState({});
  const { districts, divisions, parishes, mun_name, municipality } =
    FilterOptions(form);

  // const { getSummaryData, reports, error, setFilter, filter, loading } =
  //   useDashboard();

  useEffect(() => {
    getDevControlSummaryData({
      api: DEVELOPMENT_CONTROL_API,
      params: { ...filters },
    });
  }, [filters]);

  useEffect(() => {
    updateFormValues({
      municipality: municipality,
    });
  }, [municipality]);

  const frontDesk = [
    tileModel(
      "Registrations",
      numberFormatter(reports?.sum_registrations),
      percentageChange(reports?.year_reg_count, reports?.last_year_reg_count)
        .full_text,
      RegisterIcon
    ),
    tileModel(
      "Assessed",
      numberFormatter(reports?.sum_assessed),
      percentageChange(reports?.year_assess_count, reports?.last_assess_count)
        .full_text,
      Payment
    ),
    tileModel(
      "Amount Assessed",
      currencyFormatter(reports?.sum_assessments),
      percentageChange(
        reports?.year_assess_amount,
        reports?.last_year_assess_amount
      ).full_text,
      AssessIcon
    ),
    tileModel(
      "Amount Collected",
      currencyFormatter(reports?.sum_collections),
      percentageChange(
        reports?.year_coll_amount,
        reports?.last_year_coll_amount
      ).full_text,
      CollectionsIcon
    ),
  ];

  const physicalPlanning = [
    tileModel(
      "Approved",
      numberFormatter(reports?.year_permits_by_status?.APPROVED),
      descDC,
      CheckCircleOutlineOutlined
    ),
    tileModel(
      "Deferred",
      numberFormatter(reports?.year_permits_by_status?.DEFERRED),
      descDC,
      PauseCircleTwoTone
    ),
    tileModel(
      "Rejected",
      numberFormatter(reports?.year_permits_by_status?.REJECTED),
      descDC,
      DoNotDisturbOn
    ),
  ];

  const frontDeskOfficer = [
    tileModel(
      "Submitted",
      numberFormatter(reports?.sum_registrations),
      descDC,
      CheckCircleOutlineOutlined
    ),
    tileModel(
      INSPECTED_STATUS,
      numberFormatter(
        reports?.year_permits_by_status?.[INSPECTED_STATUS.toUpperCase()]
      ),
      descDC,
      CheckCircleOutlineOutlined
    ),
    tileModel(
      "Reviewed",
      numberFormatter(reports?.year_permits_by_status?.REVIEWED),
      descDC,
      CheckCircleOutlineOutlined
    ),
  ];

  const frontDeskOfficerRow2 = [
    tileModel(
      "Pending Inspection",
      numberFormatter(reports?.year_permits_by_status?.["PENDING INSPECTION"]),
      descDC,
      CheckCircleOutlineOutlined
    ),
    tileModel(
      "AMOUNT ASSESSED",
      currencyFormatter(reports?.sum_assessments),
      descDC,
      PauseCircleTwoTone
    ),
    tileModel(
      "AMOUNT COLLECTED",
      currencyFormatter(reports?.sum_collections),
      descDC,
      DoNotDisturbOn
    ),
  ];

  const frontDeskOfficerSet2 = [
    tileModel(
      "Submitted",
      numberFormatter(reports?.year_permits_by_status?.PENDING),
      descDC,
      CheckCircleOutlineOutlined
    ),
    tileModel(
      "Fowarded",
      numberFormatter(reports?.year_permits_by_status?.["PENDING ASSESSMENT"]),
      descDC,
      CheckCircleOutlineOutlined
    ),
    tileModel(
      "Deferred",
      numberFormatter(reports?.year_permits_by_status?.DEFERRED),
      descDC,
      PauseCircleTwoTone
    ),
    tileModel(
      "Assessed",
      numberFormatter(reports?.year_permits_by_status?.ASSESSED),
      descDC,
      DoNotDisturbOn
    ),
  ];

  const permitTiles = [
    tileModel(
      "PENDING INSPECTION",
      numberFormatter(reports?.year_permits_by_status?.["PENDING INSPECTION"]),
      descDC,
      HourglassBottomTwoTone
    ),
    tileModel(
      "INSPECTED",
      numberFormatter(reports?.year_permits_by_status?.INSPECTED),
      descDC,
      Description
    ),
    tileModel(
      "REVIEWED",
      numberFormatter(reports?.year_permits_by_status?.REJECTED),
      descDC,
      Visibility
    ),
  ];

  const enforcement = [
    tileModel(
      "Compliant",
      numberFormatter(reports?.compliant_enforcements),
      null,
      CreditCard
    ),
    tileModel(
      "Non-Compliant",
      numberFormatter(reports?.non_compliant_enforcements),
      null,
      CreditCardOffOutlined
    ),
  ];

  const frontDeskTiles = [
    tileProps(
      <p>
        Development Control Performance{" "}
        {mun_name && <TagSpan>{mun_name}</TagSpan>}
      </p>,
      frontDesk,
      tagDC
    ),
    tileProps(null, permitTiles),

    tileProps("Enforcement (Compliant & Non-Compliant)", enforcement, tagDC),
    tileProps("Physical Planning Committee Decision", physicalPlanning, tagDC),
  ];

  function getDevelopmentControlTiles() {
    var tiles = [];

    switch (status) {
      case FRONT_DESK_OFFICER:
        tiles = [
          tileProps(
            <p>
              Development Control Performance{" "}
              {mun_name && <TagSpan>{mun_name}</TagSpan>}
            </p>,
            frontDeskOfficer,
            tagDC
          ),

          tileProps(null, frontDeskOfficerRow2),

          tileProps(
            "Enforcement (Compliant & Non-Compliant)",
            enforcement,
            tagDC
          ),
        ];
        break;
      default:
        if (developmentControlRoles.includes(status)) {
          tiles = [
            tileProps(
              <p>
                Development Control Performance{" "}
                {mun_name && <TagSpan>{mun_name}</TagSpan>}
              </p>,
              frontDeskOfficer,
              tagDC
            ),

            tileProps(null, frontDeskOfficerRow2),

            tileProps(
              "Enforcement (Compliant & Non-Compliant)",
              enforcement,
              tagDC
            ),
            tileProps(
              "Physical Planning Committee Decision",
              physicalPlanning,
              tagDC
            ),
          ];
        }

        break;
    }
    return tiles;
  }

  const developmentControlDashboard = {
    tileData: getDevelopmentControlTiles(),
    statData: {
      title: [
        ["Performance Report", "Today"],
        ["Permit Performance By Collection"],
      ],
      table: {
        filter: {
          setFilter,
          form,
          updateFormValue,
          updateFormValues,
          districts,
          divisions,
          parishes,
          error,
          financial_years: reports?.financial_years,
          loading,
        },
        ammend_column_keys: ["Applications", "Assessments", "Collections"],
        rows: reports?.cumulative_report,
        totals: {
          assessments: reports?.sum_assessments,
          registrations: reports?.sum_registrations,
          collections: reports?.sum_collections,
        },
      },
      graph: reports?.cumulative_report,
    },
  };

  return developmentControlDashboard;
}

export function getDashboard(role, status) {
  let dashboard = { component: <Loader /> };

  // dev control
  if (developmentControlRoles.includes(role)) {
    dashboard = DevelopmentControlSummary(role);
    return dashboard;
  }

  switch (role) {
    case ADMIN:
    case APP_AUTHORITY:
    case MUN_APPROVER:
      switch (status) {
        case STATUS_REVENUE_FORECAST:
          dashboard = RevenueForecast();
          break;

        case STATUS_BUDGET_PERFORMANCE:
          dashboard = BudgetPerformanceReport();
          break;

        case STATUS_DEVELOPMENT_CONTROL:
          dashboard = DevelopmentControlSummary(STATUS_SUMMARY);
          break;

        case STATUS_SUMMARY_FYS:
          if (role === ADMIN) return (dashboard = ReportsSummaryFys());
          dashboard = ReportsSummary(status);
          break;

        default:
          dashboard = ReportsSummary(status);
          break;
      }
      break;

    case DATA_ENTRANT:
      dashboard = { component: <AssignedEnquiries /> };
      break;

    // valuation
    case VALUATION_APPROVER:
    case VALUATION_DATA_ENTRANT:
      dashboard = { component: <TopSection tileData={EnquiriesSummary()} /> };
      break;

    // public user
    case PUBLIC_APP_USER:
      dashboard = { component: <UserProfileView /> };
      break;
    default:
      if (role) dashboard = ReportsSummary(status);
      break;
  }

  return dashboard;
}
